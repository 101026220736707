<template>
  <div class="step">
    <img class="success" src="@/assets/succeed_big.png" alt="success">
    <button type="button" class="step_nextbtn color-fff font-14" @click="nextStep">完成</button>
  </div>
</template>

<script>
export default {
  name: 'Step3',
  methods: {
    nextStep () {
      this.$router.push({
        path: '/login'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.step{
  position: relative;
  height: 310px;
  padding-top: 60px;
  text-align: center;
  box-sizing: border-box;
  .success {
    width: 72px;
    height: 72px;
  }
  .step_nextbtn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 73px;
    width: 85px;
    border: 0;
    padding: 0;
    height: 40px;
    outline: none;
    border-radius: 4px;
    background-image: linear-gradient(270deg, #01D18A 0%, #2483FF 100%);
    cursor: pointer;
  }
}
</style>
